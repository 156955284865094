/* would like to have this in the crupdate component but the shadow dom emulation doesn't quite work with PrimeNG's rendering */

.ui-steps.steps-crupdate-agent .ui-steps-item {
    width: 33.333333%;
}

.ui-steps.booking-crupdate-steps .ui-steps-item {
    width: 16.666666666%;
}

.ui-steps {
    margin-top: 15px;
    margin-bottom: 50px;
}

.ui-steps .ui-steps-item .ui-menuitem-link {
    height: 10px;
    padding: 0 1em;
}

.ui-steps .ui-steps-item .ui-steps-number {
    background-color: #0081c2;
    color: #FFFFFF;
    display: inline-block;
    width: 36px;
    border-radius: 50%;
    margin-top: -14px;
    margin-bottom: 10px;
}

.ui-steps .ui-steps-item .ui-steps-title {
    color: #555555;
}

.ui-panel.ui-widget {
    margin-top: 20px;
}

.ui-panel .ui-widget-header, .ui-panel-header {
    background: linear-gradient(to bottom, #9fcbf1 0%, #cee5f9 100%) !important;
    border-color: #9fcbf1;
}

.ui-widget-content {
    border: 1px solid #abd8ec;
}

dl, h5 {
    margin: 0;
    padding: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-left: 0;
    margin-top: .5rem;
    word-wrap: break-word;
}

.ui-table table {
    table-layout: auto;
    border-collapse: separate;
    border-spacing: 0px;
}

.ui-panel-footer {
    background-color: #e3f2ff;
    padding: 0 1em;
}

.ui-panel-footer button, .ui-panel-footer span {
    padding: .5em;
}

.ui-panel-footer .ui-button, .ui-panel-footer .ui-button:hover {
    color: #337ab7 !important;
    background: none !important;
    border: 0 !important;
}

.ui-panel-footer .ui-button span, .ui-panel-footer .ui-button:hover span {
    text-decoration: underline;
}

.no-content .ui-panel-content, .no-header .ui-panel-header {
    display: none;
}

/* side nav bar styles */

/* Fixed sidenav, full height */

.sidenav {
    height: 65%;
    width: 200px;
    position: absolute;
    font-family: "Verdana, Arial, Helvetica, sans-serif";
    font-size: 16px;
    z-index: 1;
    top: 1;
    bottom: 1;
    left: 0;
    background-color: #215a8f;
    overflow-x: hidden;
    padding-top: 2px;
}

/* Style the sidenav links and the dropdown button */

.sidenav a, .dropdown-btn {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 16px;
    color: #fff;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
}

/* On mouse-over */

.sidenav a:hover, .dropdown-btn:hover {
    color: #fff;
    background-color: #27405f !important;
    box-shadow: inset 0 0 7px #222;
    transition: background-color .2s
}

.sidenav-bar {
    width: 200px;
}

/* Main content */

.main {
    margin-left: 200px;
    /* Same as the width of the sidenav */
    font-size: 16px;
    /* Increased text to enable scrolling */
    padding: 0px 10px;
}

/* Add an active class to the active dropdown button */

.active {
    background-color: green;
    color: white;
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */

.dropdown-container {
    display: none;
    background-color: #262626;
    padding-left: 8px;
}

/* Optional: Style the caret down icon */

.fa-caret-down {
    float: right;
    padding-right: 8px;
}

/* Some media queries for responsiveness */

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
    .sidenav a {
        font-size: 18px;
    }
}

/* side nav bar style ends */

.ui-menubar.ui-widget.ui-widget-content.ui-corner-all {
    background: #215a8f url(assets/image/highlight.png) repeat-x scroll 50% 70% !important;
    border-bottom: medium none;
    border-radius: 0 !important;
    box-shadow: 4px 4px 6px #999;
    margin: 0 !important;
    padding: 0 15px !important;
    width: auto;
    height: 40px !important;
}

.ui-menubar .ui-menuitem .ui-menuitem-link {
    padding: 0.4em 0.8em !important;
}

.ui-menubar .ui-submenu-list .ui-menuitem.ui-menuitem-active .ui-menuitem-link {
    background: #0678bc !important;
    color: #ffffff;
}

/*@media ( min-width :320px) and (max-width: 480px) {*/

/*.ui-menubar .ui-menuitem .ui-menuitem-link:active, .ui-menubar .ui-menubar-custom .sidebar-app-login a:active {*/

/*background-color: #124c85 !important;*/

/*box-shadow: inset 0 0 7px #444;*/

/*}*/

/*}*/

.ui-sidebar .ui-menubar .ui-menubar-root-list, .ui-sidebar .ui-menubar .ui-menubar-root-list>.ui-menuitem {
    background: #0678bc !important;
    color: #ffffff;
}

.ui-menubar .ui-menuitem .ui-menuitem-link.ui-state-active {
    box-shadow: inset 0 0 3px #215a8f !important;
}

.home-main.modify-team.dropdown-btn {
    width: 150px;
}

.fontFamily {
    font-family: 'Arial, Helvetica, sans-serif' !important;
}

.ui-widget, label, legend, option {
    font-family: 'Arial, Helvetica, sans-serif' !important;
}

.fontSize-12 {
    font-size: 12px !important;
}

.fontSize-13 {
    font-size: 13px !important;
}

.fontSize-14 {
    font-size: 14px !important;
}

.fontSize-15 {
    font-size: 15px !important;
}

.fontSize-16 {
    font-size: 16px !important;
}

.fontSize-20 {
    font-size: 20px !important;
}

.fontBold {
    font-weight: bold !important;
}

.fontColorBlue {
    color: rgb(0, 0, 153);
}

.ui-tree {
    /*min-width: 20vw !important;*/
    height: 72vh !important;
    font-size: 12px !important;
}

.treeSliderGripHandle {
	width: 2px;
	position: relative;
	background: url('assets/image/grip_bg.png');
	background-repeat: repeat-y;
}

.grip-handle {
	top:0 !important;
	left: 0 !important;
	right: 0 !important;
	bottom: 0 !important;
	position: absolute !important;
	cursor: col-resize !important;
}

.ui-search.ui-tree {
    /* width: 23em !important;
    height: 38em !important;
    font-size: 13px !important; */
}

@media ( min-width: 960px) {
    .textAlign {
        text-align: right !important;
    }
}

@media ( min-width: 760px) and (max-width: 960px) {
    .textAlign {
        text-align: right !important;
    }
}

@media ( min-width: 480px) and (max-width: 760px) {
    .textAlign {
        text-align: left !important;
    }
    .p-offset-3 {
        margin-left: 0px;
    }
}

@media ( min-width: 320px) and (max-width: 480px) {
    .textAlign {
        text-align: left !important;
    }
    .p-offset-3 {
        margin-left: 0px;
    }
}

.textAlignCenter {
    text-align: center;
}

.textbold {
    font-weight: bold;
}

.borderColor {
    border: 2px solid black;
}

.screenHeaderStyle {
    background-color: rgb(176, 212, 243);
    text-align: center;
    font-weight: 800;
    background: linear-gradient(to bottom, #9fcbf1 0%, #cee5f9 100%) !important;
}

.apdmBackgroundColor {
    background: #F4F4F4 !important;
}

.ui-panelmenu .ui-panelmenu-header:not(.ui-state-active):hover {
    background-color: #0678bc !important;
}

.ui-panelmenu .ui-panelmenu-header:not(.ui-state-active):hover a {
    color: #ffffff !important;
}

.ui-state-default a {
    color: #0678bc;
}

.ui-state-active {
    color: #ffffff !important;
}

.ui-panelmenu .ui-panelmenu-content .ui-menuitem-link:hover {
    border-color: #c0c0c0;
    background-color: #0678bc !important;
    color: #ffffff !important;
    border-color: transparent;
}

.homeNavShadow {
    box-shadow: 2px 2px 4px #999;
}

.marginLeft-14 {
    margin-left: 14px !important;
}

.ui-table .ui-table-thead>tr>th {
    background-color: #215a8f !important;
    color: #ffffff !important;
}

.ui-table .ui-table-tfoot>tr>td {
    background-color: #fafafb !important;
    color: #222222 !important;
}

.ui-dropdown.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix.ui-dropdown-clearable {
    width: 100% !important;
}

.ui-menubar .ui-submenu-list .ui-menuitem .ui-menuitem-link {
    padding: .2em !important;
}

.ui-menubar .ui-submenu-list .ui-menu-separator {
    border-bottom: 1px solid #c7c6c6 !important;
}

.ui-widget-header {
    background: linear-gradient(to bottom, rgb(176, 212, 243) 0%, #ebedf0 100%) !important;
}

.ui-widget-content {
    border: none !important;
    background: #f4f4f4 !important;
}

.confidential {
    color: white;
    text-decoration: underline !important;
}

.danger {
    color: #B52D4F;
}

.ui-menu {
    background: #f4f4f4 !important;
}

.ui-widget-header {
    font-weight: 900 !important;
}

.ui-menu.ui-widget.ui-widget-content.ui-corner-all.ng-animate-disabled.ng-star-inserted {
    width: 100% !important;
}

.ui-menubar .ui-menubar-root-list>.ui-menuitem>.ui-menuitem-link {
    border-right: 1px #27405f solid;
}

.ui-menubar .ui-submenu-list {
    min-width: 10em !important;
}

.ui-menu .ui-menuitem-text {
    color: #0678bc;
    font-weight: bold;
}

.ui-dialog .ui-dialog-titlebar {
    /* padding: .5em .75em !important; */
    padding: 0.2em 0.5em !important;
}

.ui-tabmenu .ui-tabmenu-nav>li.ui-state-active>a.ui-menuitem-link {
    background: #215a8f;
    font-weight: bold;
    color: white !important;
}

/********Tree desgin ***************/

/* .fa-folder-open {
    color: #eac658 !important;
    font-size: 20px !important;
} */

/* #d1de0ee3 old #4478a9 */

/* color: #b9d8f2 !important;#8db4d8 // #e0c46b*/

/* .fa-folder {

    color: #eac658  !important;
    font-size: 20px !important;
} */

/* #d1de0ee3  old #8db4d8*/

.fa-folder-open {
    display: block;
    height: 22px;
    padding-left: 26px;
    background: transparent url(/assets/image/Medium/o-folder.gif) 0 0px no-repeat !important;
    content: none !important;
}

.icon-ref {
    display: block;
    height: 22px;
    padding-left: 26px;
    background: transparent url(/assets/image/Medium/shortcut.gif) 0 0px no-repeat;
}

.fa-folder {
    display: block;
    height: 22px;
    padding-left: 26px;
    background: transparent url(/assets/image/Medium/c-folder.gif) 0 0px no-repeat !important;
    content: none !important;
}

.icon-msi-selected {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/msi.gif) 0 0px no-repeat;
    background-color: #3670b9;
}

.icon-jar {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/jar.jpg) 0 0px no-repeat;
}

.icon-java {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/java.gif) 0 0px no-repeat;
}

.icon-zip {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/zipIcon.png) 0 0px no-repeat;
}

.icon-img {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/img.jpg) 0 0px no-repeat;
}

.icon-excel {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/excel.jpg) 0 0px no-repeat;
}

.icon-word {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/word.png) 0 0px no-repeat;
}

.icon-pdf {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/pdf.png) 0 0px no-repeat;
}

.icon-ppt {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/ppt.png) 0 0px no-repeat;
}

.icon-rar {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/rar.gif) 0 0px no-repeat;
}

.icon-music {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/mp3.png) 0 0px no-repeat;
}

.icon-text {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/text_icon.gif) 0 0px no-repeat;
}

.icon-xml {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/xml.gif) 0 0px no-repeat;
}

.icon-html {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/html.jpg) 0 0px no-repeat;
}

.icon-jar {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/jar.jpg) 0 0px no-repeat;
}

.icon-msi {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/msi.gif) 0 0px no-repeat;
}

.icon-fileLeaf {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/fileLeaf1.jpg) 0 0px no-repeat;
}

.icon-ref {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/shortcut.gif) 0 0px no-repeat;
}

.icon-part {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/c-part.gif) 0 0px no-repeat;
}

.icon-query {
    display: block;
    height: 22px;
    padding-left: 22px;
    background: transparent url(/assets/image/Medium/c-save-query.gif) 0 0px no-repeat;
}

.fa-folder:before {
    content: none;
}

.fa-folder-open:before {
    content: none;
}

.pi-file {
    color: #4478a9 !important;
    font-size: 20px !important;
}

.fa-file-image {
    color: #ec6666 !important;
    font-size: 20px !important;
    background: #e6e60d;
}

.fa-file-archive {
    color: #f1e66d !important;
    font-size: 20px !important;
    background-color: #272525;
}

.fa-file-excel {
    color: #38b718 !important;
    font-size: 20px !important;
    background-color: ivory;
}

.fa-file {
    color: #c4c7ab !important;
    font-size: 20px !important;
    background-color: ivory;
}

.fa-file-pdf {
    color: #d41b1b !important;
    font-size: 20px !important;
    background-color: ivory;
}

.fa-file-word {
    color: #3371ab !important;
    font-size: 20px !important;
    background-color: #bbd4dc;
}

.fa-file-powerpoint {
    color: #ec6666 !important;
    font-size: 20px !important;
    background: #e8f5efc4;
}

.fa-database {
    color: #8db4d8 !important;
    font-size: 20px !important;
}

.ui-treenode-content {
    font-size: 14px;
}

.fa-file-video {
    color: #8ab4c3c4 !important;
    font-size: 20px !important;
    background: #5e56d6;
}

.fa-java {
    color: #ec6666 !important;
    content: "\f4e4";
    font-size: 20px !important;
    background: #e8f5efc4;
}

.ui-fileupload-content {
    padding: 0.1em !important;
}

.ui-dialog.ui-widget .ui-dialog-content {
    padding: 0.2em 0.7em !important;
    /* overflow: visible; */
}

.car-folder {
    display: block;
    height: 22px;
    padding-left: 26px;
    background: transparent url(/assets/image/Medium/c-car.gif) 0 0px no-repeat;
}

.car-folder-open {
    display: block;
    height: 22px;
    padding-left: 26px;
    background: transparent url(/assets/image/Medium/o-car.gif) 0 0px no-repeat;
}

.icon-program-closed {
    display: block;
    height: 22px;
    padding-left: 26px;
    background: transparent url(/assets/image/Medium/c-program.gif) 0 0px no-repeat;
}

.icon-program-open {
    display: block;
    height: 22px;
    padding-left: 26px;
    background: transparent url(/assets/image/Medium/o-program.gif) 0 0px no-repeat;
}

.icon-query {
    display: block;
    height: 22px;
    padding-left: 26px;
    background: transparent url(/assets/image/Medium/c-save-query.gif) 0 0px no-repeat;
}

.icon-query-open {
    display: block;
    height: 22px;
    padding-left: 26px;
    background: transparent url(/assets/image/Medium/o-save-query.gif) 0 0px no-repeat;
}

.icon-part-selected {
    display: block;
    height: 22px;
    padding-left: 26px;
    background: transparent url(/assets/image/Medium/c-part.gif) 0 0px no-repeat;
}

.icon-part-selected-open {
    display: block;
    height: 22px;
    padding-left: 26px;
    background: transparent url(/assets/image/Medium/o-part.gif) 0 0px no-repeat;
}

.url-file {
    display: block;
    height: 22px;
    padding-left: 26px;
    background: transparent url(/assets/image/Medium/html.jpg) 0 0px no-repeat;
}

/* .ref-file {
    display: block;
    height: 22px;
    padding-left: 36px;
    background-image: url(/apdmweb/assets/image/Medium/c-folder.gif), url(/apdmweb/assets/image/Medium/shortcut.gif);
    background-position: left bottom, right bottom;
    background-repeat: no-repeat, no-repeat;
} */

/****datatable *******/

/* .ui-table-scrollable-header-box{
	margin-right: 15px!important;
	}

.ui-table-scrollable-body{
    overflow-y: scroll !important;
  }


  .ui-table-scrollable-header{
   width: 100%;
} */

*::-ms-backdrop, .ui-widget {
    font-family: "Segoe UI", "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;
}

.non-scrollable-table .ui-table-wrapper {
    overflow-x: visible !important;
}

#app-header #app-branding h1 {
    font-size: 10px !important;
}

#app-header #app-branding {
    padding: 0em 0;
}

.nobull {
    list-style-type: none;
}

/**** datatable *******/

/******Open Tab******/

.opentab {
    height: 5em !important;
    font-size: 13px !important;
}

.picker {
    background-color: #eee;
    padding: 1em;
}

.ui-tabview .ui-tabview-panel {
    padding: 0.5em !important;
}

.ui-tabview .ui-tabview-nav>li.ui-state-active {
    background: #215a8f;
    font-weight: bolder;
}

.ui-state-active a {
    color: #ffffff !important;
}

.pmessage {
    background-color: #00bfff;
}

.ui-menubar .ui-menuitem-active>.ui-submenu>.ui-submenu-list {
    display: block;
    width: 180px;
}

.viewScreen {
    font-size: 13px;
    font-family: 'Arial, Helvetica, sans-serif' !important;
}

.ui-chkbox-label {
    /* vertical-align: middle; */
    font-weight: normal;
}

/****validation*****/

.invalid-feedback {
    color: red;
}

/****validation**/

input[type="text"], input[type="number"] {
    height: 27px !important;
}

/*****loader******/

.ui-progressbar-indeterminate .ui-progressbar-value::before {
    content: '';
    position: absolute;
    background-color: #4478a9 !important;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: ui-progressbar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    animation: ui-progressbar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.ui-progressbar-indeterminate .ui-progressbar-value::after {
    content: '';
    position: absolute;
    background-color: #4478a9 !important;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: ui-progressbar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation: ui-progressbar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    -webkit-animation-delay: 1.15s;
    animation-delay: 1.15s;
}

/*******************/

.DivToScroll {
    background-color: #F5F5F5;
    border: 1px solid #DDDDDD;
    border-radius: 4px 0 4px 0;
    color: #3B3C3E;
    font-size: 12px;
    font-weight: bold;
    left: -1px;
    padding: 10px 7px 5px;
}

.DivWithScroll {
    min-height: 80px;
    height: 150px;
    overflow: scroll !important;
    overflow-x: hidden;
}

.blueFont {
    color: blue;
}

.greenFont {
    color: green;
}

.viewSubject {
    margin-left: 147px;
    ;
}

.checkbuttonWidth {
    width: 100% !important;
}

.viewNone {
    margin-left: 4px;
}

.notify {
    font-size: 10px !important;
    color: #B52D4F;
    font-family: Arial, Helvetica, sans-serif;
}

.notifynocolor {
    font-size: 10px !important;
    font-family: Arial, Helvetica, sans-serif;
}

.textBoxWidthForEditProerties {
    width: 50% !important;
}

.tabForEditProerties {
    padding: 0.1em !important;
}

.ui-chkbox .ui-chkbox-box {
    border-color: #908e8e !important;
}

.ui-table .ui-table-thead>tr>th {
    background: linear-gradient(to bottom, #9fcbf1 0%, #cee5f9 100%) !important;
    background: linear-gradient(to bottom, #9fcbf1 0%, #cee5f9 100%) !important;
    background: linear-gradient(to bottom, #9fcbf1 0%, #cee5f9 100%) !important;
    background: linear-gradient(to bottom, #9fcbf1 0%, #cee5f9 100%) !important;
    background: linear-gradient(to bottom, #9fcbf1 0%, #cee5f9 100%) !important;
    color: #0c0000 !important;
}

.adminTextBox {
    height: 15px !important;
}

.ui-table .ui-table-tbody>tr>td {
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
}

/* .ui-table .ui-table-tbody>tr:nth-child(even).ui-contextmenu-selected {
    background-color: #186ba0;
    color: #FFFFFF;
} */

.whatsnew {
    background: transparent url(/assets/image/whatsnewbg.jpg)!important;
}

.successCount {
    color: rgb(50, 181, 45);
}

.successStatusCheckin {
    color: #000000 !important;
    background: #78fb9a !important;
}

.failureStatusCheckin {
    color: #000000;
    background: #f56f6f !important;
}

.inProgressStatusCheckin {
    color: #000000;
    background: #e6ef9d !important;
}

.cancelledStatusCheckin {
    color: #000000;
    background: #e6ef9d !important;
}

.ui-listbox.ui-inputtext.ui-widget.ui-widget-content.ui-corner-all {
    width: 100% !important;
}

.rightTextalignment {
    text-align: right !important;
}

.ui-autocomplete-dd input.ui-corner-all, .ui-autocomplete-dd .ui-autocomplete-multiple-container.ui-corner-all {
    width: 94% !important;
}

.inputTextWidth-50 {
    width: 50% !important;
}

.inputTextWidth-60 {
    width: 60% !important;
}

.inputTextWidth-90 {
    width: 90% !important;
}

.inputTextWidth-20 {
    width: 20% !important;
}

.part-folder {
    display: block;
    height: 22px;
    padding-left: 26px;
    background: transparent url(/assets/image/Medium/c-part.gif) 0 0px no-repeat;
}

.part-folder-open {
    display: block;
    height: 22px;
    padding-left: 26px;
    background: transparent url(/assets/image/Medium/o-part.gif) 0 0px no-repeat;
}

.checkOutDialog {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
}

.downloadSize {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 9px;
    color: #0678bc;
}

.ownertextwrap {
    word-wrap: break-word !important;
}

.text-align-right {
    text-align: right;
}

.ui-dialog-footer-button {
    border: 1px solid #dad6d6;
    padding: 5px;
}

.storage-input {
    margin-bottom: -15px;
}

.anyfoldersearch-input{
    margin-bottom: -15px;
}
.searchschedule-input {
    margin-bottom: -6px;
}

.ui-table .ui-table-tbody>tr:hover {
    background-color: #186ba0 !important;
    color: #FFFFFF !important;
}

.ui-dropdown-clear-icon{
    right: 1.5em !important;
}

.label-subject {
    width: 150px !important;
}

.label-fuel {
    width: 120px !important;
}

.ui-level-dropdown {
    max-height: none !important;
}

.search-desc-content {
	overflow: auto;
	height: 72vh;
}

.search-desc-content-view-person {
	overflow: auto;
	height: 400px;
}
.engine-build-context {
	top:250px !important;
}

.ui-tree .ui-tree-container {
    overflow: scroll;
}

.search-ui-tree-container {
    overflow: inherit !important;
}

.tperson-search-grid-datatable {
    width: 117% !important;
}

.tperson-collapse {
    width: 95% !important;
}

.create-user-title {
    text-align: left;
}

.project-textarea {
    height: 100px;
}

.checkbox-col {
    width: 26% !important;
}

.person-id-cdsid {
    width: 10.3333% !important;
    margin-top: 10px;
}

.person-id-label {
    width: 5.3333% !important;
}

.person-checkbox {
    width: 9.3333% !important;
    margin-top: 5px;
}

.acl-data {
    width:12.3333% !important;
    margin-top: 5px;
}

.team-checkbox {
    width:8.111% !important;
    margin-top: 5px;
}

.team-acl-data {
    width: 10.5833% !important;
    margin-top: 5px;
}

.slow-text {
    width: 5.5833% !important;
    margin-top: 5px;
}

.show-checkbox {
    width: 8% !important;
}

.show-checkbox-25 {
    width: 25% !important;
}

#team-name {
    width: 15% !important;
}

#person-checkbox {
    width: 8.5% !important;
}

#org-checkbox {
    width: 12% !important;
}

#role-checkbox {
    width: 6.5% !important;
}

#automation-checkbox {
    width: 11% !important;
}

#team-checkbox {
    width: 7.5% !important;
}

.checkBoxWidth{
    width: 9.5%;
}

.team-obj-lable {
    width: 23.6667% !important;
}
@media (max-width: 960px) and (min-width: 760px){
    .btn-align {
        text-align: center;
        align-items: center;
    }
    }
    @media (max-width: 760px) and (min-width: 480px)
    {
        .btn-align {
            text-align: center;
            align-items: center;
        }
    }

.object-attr-grid {
    margin-bottom: -10px;
}

.body-form {
    height: 480px !important;
}

.create-file-ui-dialog-content {
    border: 1px solid #dad6d6 !important;
    margin: 7px;
    overflow-y: scroll !important;
}

.adminFieldBackGround{
    background: #dddddd;
    border-color: #ffffff !important;
    border-style: solid;
    border-width: thin;
}

.adminLabelBackGround{
    background: #eedec4;
    border-color: #ffffff !important;
    border-style: solid;
    border-width: thin;
}

.adminLabelBackGroundForReq{
    background: #dd9999;
    border-color: #ffffff !important;
    border-style: solid;
    border-width: thin;
}

.adminLabelBackGroundForOpt{
    background: #999966;
    border-color: #ffffff !important;
    border-style: solid;
    border-width: thin;
}



