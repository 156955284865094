/********************************* [START] Ford Fonts *********************************/
/* This CSS resource incorporates links to font software which is the valuable copyrighted property of WebType LLC, The Font Bureau, Ascender Corporation and/or their suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact WebType with any questions: http://www.webtype.com */

@font-face {
	/* Ford Antenna Bold */
	font-family: Antenna;
	src: url("../fonts/Ford-Antenna-Bold/3fcdf9c8-1bf9-49f4-b9e9-48fba95ab07b-3.woff") format("woff"),
	url("../fonts/Ford-Antenna-Bold/3fcdf9c8-1bf9-49f4-b9e9-48fba95ab07b-1.ttf") format("truetype");
	font-style: normal;
	font-weight: 700;
}

@font-face {
	/* Ford Antenna Medium */
	font-family: Antenna;
	src: url("../fonts/Ford-Antenna-Medium/16cfc826-eb8a-47b2-8d9b-928e47f8157e-3.woff") format("woff"),
	url("../fonts/Ford-Antenna-Medium/16cfc826-eb8a-47b2-8d9b-928e47f8157e-1.ttf") format("truetype");
	font-style: normal;
	font-weight: 500;
}

@font-face {
	/* Ford Antenna Regular */
	font-family: Antenna;
	src: url("../fonts/Ford-Antenna-Regular/d9e5c1ae-3c12-46d7-97a6-a7ff0861e239-3.woff") format("woff"),
	url("../fonts/Ford-Antenna-Regular/d9e5c1ae-3c12-46d7-97a6-a7ff0861e239-1.ttf") format("truetype");
	font-style: normal;
	font-weight: 400;
}
@font-face {	/* Ford Antenna Light */
	font-family: AntennaLight;
	src: url("../fonts/Ford-Antenna-Light/11f33cf4-3e86-4d2b-b2df-9e2ae91ba4b5-3.woff") format("woff"),
	url("../fonts/Ford-Antenna-Light/11f33cf4-3e86-4d2b-b2df-9e2ae91ba4b5-1.ttf") format("truetype");
	font-style: normal;
	font-weight: 300;
}
@font-face {	/* Ford Antenna Light Italic */
	font-family: AntennaLight;
	src: url("../fonts/Ford-Antenna-Light-Italic/2f230fc8-026f-4a1a-82f5-2f116e1e5853-3.woff") format("woff"),
	url("../fonts/Ford-Antenna-Light-Italic/2f230fc8-026f-4a1a-82f5-2f116e1e5853-1.ttf") format("truetype");
	font-style: italic;
	font-weight: 300;
}
@font-face {	/* Ford Antenna Condensed Bold */
	font-family: AntennaCond;
	src: url("../fonts/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-3.woff") format("woff"),
	url("../fonts/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-1.ttf") format("truetype");
	font-style: normal;
	font-weight: 700;
}

@font-face {
	/* Ford Antenna Condensed Medium */
	font-family: AntennaCond;
	src: url("../fonts/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-3.woff") format("woff"),
	url("../fonts/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-1.ttf") format("truetype");
	font-style: normal;
	font-weight: 500;
}

@font-face {
	/* Ford Antenna Condensed Regular */
	font-family: AntennaCond;
	src: url("../fonts/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-3.woff") format("woff"),
	url("../fonts/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-1.ttf") format("truetype");
	font-style: normal;
	font-weight: 400;
}

/********************************* [END] Ford Fonts *********************************/

/* override PrimeNG theme font family */
$fontFamily: "Trebuchet MS", Arial, Helvetica, sans-serif;
$fontFamilyAntenna: Antenna, Arial, Helvetica, sans-serif;
$fontFamilyAntennaLight: AntennaLight, Arial, Helvetica, sans-serif;
$fontFamilyAntennaCond: AntennaCond, Arial, Helvetica, sans-serif;


body{
	font-family: $fontFamily;
	margin: 0;
}

html, body, app-root{
	height: 100%;
}

app-root{
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: stretch;
	align-content: stretch;
}
#app-header{
	flex-shrink: 0;
	flex-basis: auto;
	margin: 0 0.5em 1em;
}
#app-content{
	height: auto;
	flex-grow: 2;
	margin: -3px 1.5em;
}
#app-footer{
	flex-shrink: 0;
	flex-basis: auto;
	width: 100%;
	margin-top: -25px;
	background-image: linear-gradient(to bottom,#eee 0,#fff 100%);
}


.ui-widget, label, legend, option {
	font-family: $fontFamily;
}

/* TODO: clean these up! */
label, legend {
	font-weight: 700;
}

fieldset label {
	font-weight: 400;
	/*font-size: 11px;*/
}

table .ui-widget-content td:first-child {
	overflow: visible;
}

/* then, where they should be used, bring in the Antenna fonts */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $fontFamilyAntennaCond;
	margin: 0 0 .5em;
}

h5, h6,
.h5, .h6 {
	font-family: $fontFamilyAntenna;
}

a, a:focus{
	color: #0678bc;
	text-decoration: none;
}
a:hover{
	color: #27405f;
}

p{
	margin: 0 0 10px;
}

hr{
	margin-bottom: 1em;
	border: 0;
	border-top: 1px solid #eee;
}

#app-branding h1 {
	font-family: $fontFamilyAntenna;
	font-weight: 700;
	font-size: 1.125rem;
	color: #036;
}

#app-branding h1 a {
	text-decoration: none;
	&:hover,
	&:focus {
		cursor: pointer;
	}
}

.ui-menu {
	background: #215A8F;
}

.ui-state-active {
	background: #428bca;
}


/* Header <40-kgnanara> */

#app-header #app-branding h1 {
	margin: 0;
	line-height: 1.5em;
	font-weight: 200;
	font-size: 30px;
	display: inline-block;
}

#app-header #app-branding{
	flex-wrap: nowrap;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 1em 0;
}
#app-header #app-branding #app-icon{
	align-self: center;
	text-align: center;
	color: #425968;
	font-size: 13px;
}
#app-header #app-branding #app-name{
	flex-grow: 1;
	padding: 0 1em;
	align-self: center;
}
#app-header #app-branding #app-login{
	float: right;
	text-align: right;
	align-self: center;
}

#app-header #app-branding #app-icon img{
	display: inline-block;
}

#app-header #app-branding #app-login a{
	margin: 0 .5em;
}
#app-header #app-branding #app-login a .fa{
	padding-right: .25em;
}

#app-footer > div {
	width: 100%;
	background-image: linear-gradient(to bottom,#eee 0,#fff 100%);
}

#app-branding #app-login{display:block;}
.sidebar-app-login, .btn-sidebar{display: none;}


@media (min-width: 50px) and (max-width: 767px){
	.ui-menubar, #app-branding #app-login{display:none;}
	.ui-sidebar .ui-menubar, .sidebar-app-login, .btn-sidebar{display: block;}
	.quicklinks-list li a{padding: .15em;}
	#app-footer *{text-align: center;}
	#app-branding .logo{max-width: 90px; transition: max-width .5s ease-in;}

}

@media (min-width: 50px) and (max-width: 479px) {
	#app-header #app-branding #app-name h1{font-size: 18px; transition: font-size .5s ease-in;}
	#app-header #app-branding #app-name{text-align: center;}
	#app-header #app-branding .logo{max-width: 95px; transition: max-width .5s ease-in;}
	#app-header #app-branding #app-icon{align-self: flex-start !important;}
}

@media (min-width: 480px) {
	#app-header #app-branding #app-name h1{font-size: 20px;transition: font-size .5s ease-in;}
	#app-header #app-branding .logo{max-width: 100px; transition: max-width .5s ease-in;}
}

@media (min-width: 768px) {
	#app-header #app-branding #app-name h1{font-size: 22px;transition: font-size .5s ease-in;}
	#app-header #app-branding .logo{max-width: 105px; transition: max-width .5s ease-in;}
}

@media (min-width: 960px) {
	#app-header #app-branding #app-name h1{font-size: 24px;transition: font-size .5s ease-in;}
	#app-header #app-branding .logo{max-width: 110px; transition: max-width .5s ease-in;}
}

@media (min-width: 1024px) and (max-width: 1286px) {
	#app-header #app-branding #app-name h1{font-size: 26px; transition: font-size .5s ease-in;}
	#app-header #app-branding .logo{max-width: 115px; transition: max-width .5s ease-in;}
}
@media (min-width: 1287px) {
	#app-header #app-branding #app-name h1{font-size: 28px; transition: font-size .5s ease-in;}
	#app-header #app-branding .logo{max-width: 120px; transition: max-width .5s ease-in;}
}

/* [End] Header */



/* Menu Bar <40-kgnanara> */
.ui-menubar {
	background: #215a8f;
	border: none;
	padding: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}
.ui-menubar ul{
	line-height: 1 !important;
}
.ui-menubar .ui-menubar-root-list{
	margin: 0;
}
.ui-state-active {
	background: #428bca;
}

.ui-menubar .ui-menuitem .ui-menuitem-link { /* First Level items */
	text-decoration: none;
	color: #fff;
	background: none !important;
	padding: 1em !important;
	border-radius: 0;
}
.ui-menubar .ui-menuitem .ui-menuitem-link:hover, .ui-menubar .ui-menubar-custom .sidebar-app-login a:hover{
	background-color: #124c85 !important;
}
.ui-menubar .ui-menuitem .ui-menuitem-link:active, .ui-menubar .ui-menubar-custom .sidebar-app-login a:active{
	background-color: #124c85 !important;
	-moz-box-shadow: inset 0 0 7px #444;
	-webkit-box-shadow: inset 0 0 7px #444;
	box-shadow: inset 0 0 7px #444;
}
.ui-menubar .ui-menuitem .ui-menuitem-link.ui-state-active{
	background-color: #27405f !important;
	-moz-box-shadow: inset 0 0 7px #222;
	-webkit-box-shadow: inset 0 0 7px #222;
	box-shadow: inset 0 0 7px #222;
	transition: background-color .5s ease-in;
}
.ui-menubar .ui-menuitem.ui-menuitem-active, .ui-menubar .ui-menuitem.ui-menuitem-active .ui-menuitem-link{ /* First Level items (Active) */
	background: none;
}
.ui-menubar .ui-submenu-list{
	background: #fff;
	min-width: 15em;
}
.ui-menubar .ui-submenu-list .ui-menuitem .ui-menuitem-link { /* Second Level items */
	padding: .5em !important;
	color: #0678bc;
	background: #fff;
}
.ui-menubar .ui-submenu-list .ui-menuitem.ui-menuitem-active .ui-menuitem-link { /* Second Level items (Active) */
	background: #eee !important;
	color: #0678bc;
}

.ui-menubar .ui-menu-parent .ui-menu-child{
	background: #fff;
}
.ui-sidebar{
	padding: 0;
	border: 0;
	overflow-y: auto;
}
.ui-sidebar .ui-menubar .ui-menubar-root-list, .ui-sidebar .ui-menubar .ui-menubar-root-list>.ui-menuitem{
	display: block;
	width: 100%;
}
.ui-sidebar .ui-menubar .ui-menuitem .ui-menuitem-link{
	border-bottom: 1px solid #888;
}

.ui-sidebar .ui-menubar .ui-submenu-list .ui-menuitem .ui-menuitem-link{
	border-bottom: 1px solid #ccc;
}
.ui-sidebar .ui-menubar .ui-submenu-list, .ui-sidebar .ui-menubar .ui-menu-parent .ui-menu-child{
	position: static;
	width: 100%;
}
.ui-sidebar-close{
	z-index: 10000;
	position: absolute;
	color: #003478;
	background: #ccc;
	right: 0;
}

.btn-sidebar button{
	background: #27405f;
	border: 0;
	outline: none;
	padding: .25em .5em;
	font-size: 1.5em;
	color: #fff;
	border-radius: 20%;
}
.ui-menubar .ui-menubar-custom{
	float: none;
	margin: 0;
	padding: 0;
}
.ui-menubar .ui-menubar-custom .sidebar-app-login a{
	color: #fff !important;
	display: block;
	width: 100%;
	background: none;
	padding: .75em;
	border-radius: 0;
	border-bottom: 1px solid #888;
}
.ui-menubar .ui-menubar-custom .sidebar-app-login a:hover, .ui-menubar .ui-menubar-custom .sidebar-app-login a:active, .ui-menubar .ui-menubar-custom .sidebar-app-login a:focus{
	color: #fff;
}
.ui-menubar .ui-menubar-custom .sidebar-app-login .fa{
	color: #fff;
}

/* [End] Menu Bar <40-kgnanara> */

h1,
.h1 {
	font-weight: normal;
	font-size: 2.4rem;
	margin-bottom: 10px;
}

h2,
.h2 {
	margin-bottom: 10px;
	//color: #336CA2;
}

h3,
.h3 {
	color: #777;
}

h4,
.h4 {
	color: #444;
}

h5,
.h5 {
	color: #666;
}

h6,
.h6 {
}

.ui-datatable th.ui-state-default.ui-state-active {
	background: #0d73b0;
}

.ui-datatable th.ui-state-default {
	background: #215A8F;
	color: #fff;
}

.ui-datatable th.ui-sortable-column:not(.ui-state-active):hover {
	background: #215A8F;
	color: #fff;
}

.ui-steps .ui-steps-title {
	font-family: $fontFamilyAntennaCond;
	font-size: 0.9rem;
}

/* primeng tables are fixed cell widths. gross. override back to default */
.ui-datatable table {
	table-layout: auto;
}

.ui-datatable table th,
.ui-datatable table td {
	width: auto;
}

/* primeng doesn't have layout helpers. add them here */
.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}
.items-center{
	display: flex;
	align-items: center;
}

/* primeng doesn't provide much form styling. add back in */
input[type="text"], input[type="number"], textarea, select {
	width: 100%;
	padding: 5px;
}
.ui-autocomplete, .ui-autocomplete .ui-autocomplete-multiple-container.ui-inputtext{
	width: 100%;
}
.ui-autocomplete-token{
	margin: .125em;
	font-size: 100%;
}

input[type="text"], input[type="number"] {
	height: 34px;
}

fieldset {
	padding: 0;
	margin: 0;
	border: 0;
}

.form-actions {
	border-top: 1px solid #e5e5e5;
	padding: 1em;
	margin: 0 -5px 5px -5px;
}

/* Select All None Links */
